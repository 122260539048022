import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectBalancesInfo, selectPairsData, selectSettingsItem } from 'redux/selectors';
import { Event, SocketContext } from 'SocketIO';
import { dispatch as CustomDispatch } from 'redux/actions/index';
import { deepCompare } from 'assets/js/common';
import Api from 'api';

let noneStates = {
  previousEntitiesData: null,
  previousPricesData: null,
  processingIds: null,
};

export default function AlertProcessor(props) {
  const { entities, tvWidget, pairStrat } = props;
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const alertList = useSelector(selectSettingsItem('alertList'));
  const pairsData = useSelector(selectPairsData);

  const [pricesData, setPricesData] = useState(null);
  const [isAbleToStart, setAbleToStart] = useState(false);
  const balancesInfo = useSelector(selectBalancesInfo);

  const loadList = () => {
    socket.emit('alert_list', list => {
      dispatch(CustomDispatch('putState', list, 'alertList'));
    });
    socket.emit('check_alert_processor');
  };

  const handleAlertCreated = data => {
    dispatch(CustomDispatch('putState', [...alertList, data], 'alertList'));
  };
  const handleAlertRemoved = id => {
    noneStates.processingIds = noneStates.processingIds.filter(_id => _id !== id);
    dispatch(
      CustomDispatch(
        'putState',
        alertList.filter(alert => alert.id !== id),
        'alertList',
      ),
    );
  };
  const handleAlertUpdated = alert => {
    noneStates.processingIds = noneStates.processingIds.filter(_id => _id !== alert.id);
    dispatch(
      CustomDispatch(
        'putState',
        alertList.map(_alert => (_alert.id === alert.id ? Object.assign(_alert, alert) : _alert)),
        'alertList',
      ),
    );
  };

  useEffect(loadList, []);

  const calculateLinearPrice = (point1, point2) => {
    return (
      point1.price + ((point2.price - point1.price) * (Date.now() / 1000 - point1.time)) / (point2.time - point1.time)
    );
  };

  useEffect(() => {
    if (
      isAbleToStart &&
      noneStates.previousPricesData &&
      tvWidget &&
      pricesData &&
      pairStrat &&
      noneStates.previousEntitiesData
    ) {
      alertList.forEach(alert => {
        if (noneStates.processingIds.indexOf(alert.id) >= 0 || alert.status === 'processed') return;

        let currentPrice = pricesData?.[alert.key]?.[alert.action === 'Buy' ? 'Bid' : 'Ask'] || Infinity;
        let tempCurrentPrice = currentPrice;
        let previousPrice = noneStates.previousPricesData?.[alert.key]?.[alert.action === 'Buy' ? 'Bid' : 'Ask'];

        let isAbleToTrigger = true;
        for (let i = 0; i < alert.info.length; i++) {
          let info = alert.info[i];
          let condition = info.condition || '';
          let entity = info.entity;
          let conditionPrice = 0;
          if (entity.startsWith('MACD')) {
            if (condition.startsWith('MACD-H')) {
              previousPrice = noneStates.previousEntitiesData[entity]
                ? noneStates.previousEntitiesData[entity].value[0]
                : Infinity;
              currentPrice = entities[entity] ? entities[entity].value[0] : Infinity;
              conditionPrice = 0;
            } else {
              previousPrice = noneStates.previousEntitiesData[entity]
                ? noneStates.previousEntitiesData[entity].value[1]
                : Infinity;
              currentPrice = entities[entity] ? entities[entity].value[1] : Infinity;
              conditionPrice = entities[entity] ? entities[entity].value[2] : 0;
            }
          } else if (entity.startsWith('RSI')) {
            previousPrice = noneStates.previousEntitiesData[entity]
              ? noneStates.previousEntitiesData[entity].value[0]
              : Infinity;
            currentPrice = entities[entity] ? entities[entity].value[0] : Infinity;
            if (condition.endsWith('Upper Limit')) {
              conditionPrice = pairStrat.RSI_SELL_LEVEL === -1001 ? pairStrat.RSI_BUY_LEVEL : pairStrat.RSI_SELL_LEVEL;
            } else {
              conditionPrice = pairStrat.RSI_BUY_LEVEL === -1001 ? pairStrat.RSI_SELL_LEVEL : pairStrat.RSI_BUY_LEVEL;
            }
          } else if (entity.startsWith('Stochastic')) {
            previousPrice = noneStates.previousEntitiesData[entity]
              ? noneStates.previousEntitiesData[entity].value[condition.endsWith('(K)') ? 0 : 1]
              : Infinity;
            currentPrice = entities[entity] ? entities[entity].value[condition.endsWith('(K)') ? 0 : 1] : Infinity;
            if (condition.includes('Upper Limit')) {
              conditionPrice =
                pairStrat.STOCH_SELL_LEVEL === -1001 ? pairStrat.STOCH_BUY_LEVEL : pairStrat.STOCH_SELL_LEVEL;
            } else {
              conditionPrice =
                pairStrat.STOCH_BUY_LEVEL === -1001 ? pairStrat.STOCH_SELL_LEVEL : pairStrat.STOCH_BUY_LEVEL;
            }
          } else if (entity.startsWith('Stochastic RSI')) {
            previousPrice = noneStates.previousEntitiesData[entity]
              ? noneStates.previousEntitiesData[entity].value[condition.endsWith('(K)') ? 0 : 1]
              : Infinity;
            currentPrice = entities[entity] ? entities[entity].value[condition.endsWith('(K)') ? 0 : 1] : Infinity;
            if (condition.includes('Upper Limit')) {
              conditionPrice = pairStrat.STOCHRSI_SELL_LEVEL * 100;
            } else {
              conditionPrice = pairStrat.STOCHRSI_BUY_LEVEL * 100;
            }
          } else if (info.entityInfo) {
            let startPoint = { ...info.entityInfo[0] };
            let endPoint = { ...info.entityInfo[1] };
            if (parseInt(endPoint.time) > Date.now() / 1000) {
              if (entity.startsWith('Fibonacci Retracements ')) {
                let diff = startPoint.price - endPoint.price;
                conditionPrice = endPoint.price + diff * parseFloat(info.level);
              } else {
                if (entity.startsWith('Parallel Channel ') && condition.endsWith('Lower Limit')) {
                  let middlePoint = info.entityInfo[2];
                  let temp = (endPoint.price - startPoint.price) / 2;
                  startPoint.price = middlePoint.price - temp;
                  endPoint.price = middlePoint.price + temp;
                }
                conditionPrice = calculateLinearPrice(startPoint, endPoint);
              }
            }
          } else {
            let valueIndex = 0;
            if (entity.startsWith('BB')) {
              if (condition.endsWith('High')) {
                valueIndex = 1;
              } else if (condition.endsWith('Low')) {
                valueIndex = 2;
              }
            } else if (entity.startsWith('Ichimoku')) {
              valueIndex = info.level || 0;
            }
            conditionPrice = entities[entity] ? entities[entity].value[valueIndex] : 0;
          }
          if (currentPrice === Infinity) {
            isAbleToTrigger = false;
            break;
          }

          if (
            !(previousPrice < conditionPrice && conditionPrice < currentPrice && condition.startsWith('Crossing Up')) && // not crossing up
            !(
              previousPrice > conditionPrice &&
              conditionPrice > currentPrice &&
              condition.startsWith('Crossing Down')
            ) && // not crossing down
            !(conditionPrice < currentPrice && condition.startsWith('Greater Than')) && // not greater than
            !(conditionPrice > currentPrice && condition.startsWith('Less Than')) // not less than
          ) {
            isAbleToTrigger = false;
            break;
          }
        }

        if (isAbleToTrigger) {
          currentPrice = tempCurrentPrice;
          const { TRADING_LIMIT = 0 } = ((pairsData || {})[alert.key] || {})['whatstrat'] || {};
          const [exchange, pair] = (alert.key || '').split('/');
          const balanceInfo = (balancesInfo || []).find(
            balanceItem => balanceItem.exchange === exchange && balanceItem.pair === pair,
          );
          const { quoteBalance = 0, baseBalance = 0 } = balanceInfo || {};
          let buyAmount = Math.min(baseBalance, TRADING_LIMIT) / 1.005 / currentPrice;
          let sellAmount = quoteBalance;
          if (
            exchange === 'krakenFutures' ||
            exchange === 'bitmex' ||
            exchange === 'bitmex_testnet' ||
            exchange === 'kumex'
          ) {
            buyAmount = TRADING_LIMIT;
          }
          if (exchange === 'binanceFutures' || exchange === 'dydx' || exchange === 'futures_gunthy') {
            buyAmount = TRADING_LIMIT / currentPrice;
          }
          if (
            exchange === 'krakenFutures' ||
            exchange === 'bitget' ||
            exchange === 'bitget' ||
            exchange === 'bitmex' ||
            exchange === 'bitmex_testnet' ||
            exchange === 'kumex'
          ) {
            sellAmount = TRADING_LIMIT;
          }
          if (exchange === 'binanceFutures' || exchange === 'dydx' || exchange === 'futures_gunthy') {
            sellAmount = TRADING_LIMIT / currentPrice;
          }
          let requestData = {
            amt: alert.action === 'Buy' ? buyAmount : sellAmount,
            price: currentPrice,
            exch: exchange,
            pair: pair,
          };
          if (requestData.amt <= 0) return;
          noneStates.processingIds = [...noneStates.processingIds, alert.id];

          // setTimeout(() => {
          // 	socket.emit("update_alert", { id: alert.id, status: "processed" });
          // }, 1000);

          if (alert.action === 'Buy' && quoteBalance < 0.00000001 && quoteBalance > -0.00000001) {
            Api.placeBuyOrder(requestData).finally(() => {
              socket.emit('update_alert', { id: alert.id, status: 'processed' });
            });
          } else if (quoteBalance < 0.00000001 && quoteBalance > -0.00000001) {
            Api.placeSellOrder(requestData).finally(() => {
              socket.emit('update_alert', { id: alert.id, status: 'processed' });
            });
          }
        }
      });
    }
    noneStates.previousPricesData = pricesData;
    noneStates.previousEntitiesData = entities;
  }, [isAbleToStart, entities, alertList, pricesData, balancesInfo, pairsData, pairStrat]);

  useEffect(() => {
    if (!pairsData) return;
    const keys = Object.keys(pairsData);
    const newPricesData = {};
    keys.forEach(key => {
      const pairData = pairsData[key];
      newPricesData[key] = {
        Bid: pairData.Bid,
        Ask: pairData.Ask,
      };
    });
    setPricesData(old => {
      return deepCompare(old, newPricesData) ? old : newPricesData;
    });
  }, [pairsData]);

  const handleDisconnect = () => {
    setAbleToStart(false);
  };
  const handleStartAlertProcess = () => {
    setAbleToStart(true);
  };

  return (
    <Fragment>
      <Event event={'connect'} handler={loadList} />
      <Event event={'alert_created'} handler={handleAlertCreated} />
      <Event event={'alert_removed'} handler={handleAlertRemoved} />
      <Event event={'alert_updated'} handler={handleAlertUpdated} />
      <Event event="disconnect" handler={handleDisconnect} />
      <Event event="START_ALERT_PROCESS" handler={handleStartAlertProcess} />
      <Event event="STOP_ALERT_PROCESS" handler={handleDisconnect} />
    </Fragment>
  );
}

AlertProcessor.propTypes = {
  entities: PropTypes.object.isRequired,
  tvWidget: PropTypes.object,
  pairStrat: PropTypes.object,
};
